
































import { Component, Mixins } from 'vue-property-decorator';
import { ListingFilter } from 'client-website-ts-library/filters';
import { Listing, ListingStatus, MapMarker, MapSettings, OpenHomes } from 'client-website-ts-library/types';
import { API } from 'client-website-ts-library/services';
import { View } from 'client-website-ts-library/plugins';

import Masthead from '@/components/Common/Masthead.vue';
import ListingInspectionCard from '../components/ListingInspectionCard.vue';
import Loader from '../components/UI/Loader.vue';

const Map = () => import('client-website-ts-library/components/Map/Map.vue');

@Component({
  components: {
    ListingInspectionCard,
    Loader,
    Masthead,
    Map,
  },
})
export default class InspectionTimes extends Mixins(View) {
  private filter: ListingFilter = new ListingFilter({
    Statuses: [ListingStatus.Current, ListingStatus.UnderContract],
    HaveInspections: true,
  });

  private openHomes?: OpenHomes;

  private loading = true;

  private mapSettings?: MapSettings;

  mounted() {
    API.Listings.GetOpenHomes(this.filter).then((data) => {
      this.loading = false;
      this.openHomes = data;

      if (data.Days.length) {
        this.mapSettings = new MapSettings({
          Markers: data.Days[0].Listings.map((listing) => new MapMarker({
            Title: listing.Address.MicroAddress,
            Coordinate: listing.Address.Coordinates,
          })),
          Zoom: 10,
        });
      }
      console.log(this.mapSettings);
    });
  }

  getUniqueListings(listings: Listing[]) {
    const seen = new Set();
    const filteredArr = listings.filter((el) => {
      const duplicate = seen.has(el.Id);
      seen.add(el.Id);
      return !duplicate;
    });

    return filteredArr;
  }
}
